import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/aviation/*" />
    <RAC path="/zh-cn/aviation/*" />
    <RAC path="/zh-tw/aviation/*" />
    <RAC path="/pl/aviation/*" />
    <RAC path="/ru/aviation/*" />
    <RAC path="/it/aviation/*" />
    <RAC path="/de/aviation/*" />
    <RAC path="/es/aviation/*" />
    <RAC path="/fr/aviation/*" />
    <RAC path="/ar/aviation/*" />
    <RAC path="/id/aviation/*" />
    <RAC path="/pt/aviation/*" />
    <RAC path="/ko/aviation/*" />
    <DynamicNotFoundPage default />
  </Router>
)
